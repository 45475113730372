import { useFilterStore } from './filters.store';

export const useAreFiltersApplied = () => {
  const { uiEventFilterValues, globalFilters } = useFilterStore(['uiEventFilterValues', 'globalFilters']);
  const isGlobalFiltersApplied = Object.values(globalFilters).some(Boolean);
  const isUiEventFiltersApplied = !!uiEventFilterValues.length;
  const isFiltersApplied = isGlobalFiltersApplied || isUiEventFiltersApplied;

  return isFiltersApplied;
};
