import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SkeletonList } from 'components';
import { useAppStore } from 'stores/app';
import { useContainerStore } from 'stores/container';
import { useDynamicContainers } from 'pages/InspectionPage/InspectionPage.hooks';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useAreFiltersApplied, useFilterStore } from 'stores/filters';
import { ContainerList, DynamicContainersEmptyState, FiltersEmptyState } from '..';

export interface InspectionListContentProps {
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
  showEmptyState?: boolean;
}

export const InspectionListContent = ({
  collapseContainerDetails,
  displayContainerContent,
  showEmptyState = false,
}: InspectionListContentProps) => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const { inspectionDataLoading } = useAppStore(['inspectionDataLoading']);
  const { filteredContainerIds } = useFilterStore(['filteredContainerIds']);
  const { rootContainerIds, openTemplatesList } = useContainerStore(['rootContainerIds', 'openTemplatesList']);
  const { isDynamicContainers, hasMultipleDynamicContainers, createNewDynamicContainer } =
    useDynamicContainers(executionId);
  const { t } = useTranslation();
  const isFiltersApplied = useAreFiltersApplied();

  if (inspectionDataLoading) return <SkeletonList height={450} px={4} itemHeight={64} gap={20} />;

  if (isFiltersApplied && !filteredContainerIds.size) {
    return (
      <FiltersEmptyState
        title={t('inspection.emptyFilterResult.title')}
        text={t('inspection.emptyFilterResult.text')}
      />
    );
  }

  if (rootContainerIds.length) {
    return (
      <ContainerList
        containerIds={rootContainerIds}
        collapseContainerDetails={collapseContainerDetails}
        displayContainerContent={displayContainerContent}
      />
    );
  }

  if (isDynamicContainers && showEmptyState) {
    const onAddContainer = () => {
      if (hasMultipleDynamicContainers) openTemplatesList();
      else createNewDynamicContainer();
    };
    return (
      <DynamicContainersEmptyState
        title={t('inspection.emptyContainerList.title')}
        text={t('inspection.emptyContainerList.text')}
        addContainer={onAddContainer}
      />
    );
  }

  return null;
};
