import { useMemo } from 'react';
import { useGetContainerStaticEvents, useUnreportedMandatoryEvents } from 'stores/uiEvent';
import { useFocusData } from 'stores/focus';
import { useReportStore } from 'stores/report';
import { pullLastReport } from 'stores/report/report.utils';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { useContainerStore } from './container.store';

export function useContainerById(containerId?: string) {
  return useContainerStore((state) => (containerId ? state.containers[containerId] : undefined));
}

export function useMandatoryEventsWithoutDefaults() {
  const getUnreportedMandatoryEvents = useUnreportedMandatoryEvents();
  const { containers } = useContainerStore(['containers']);
  const { reports } = useReportStore(['reports']);
  const getContainerStaticEvents = useGetContainerStaticEvents();
  return useMemo(() => {
    const applicableContainers = Object.values(containers).filter((container) => {
      const { applicabilityEventId } = getContainerStaticEvents(container.id);
      const eventId = applicabilityEventId;
      const applicabilityReport = pullLastReport(reports, container.id, eventId ?? '');
      return applicabilityReport?.reportedValue !== ApplicabilityReportValue.NOT_APPLICABLE;
    });
    return applicableContainers.flatMap((container) => getUnreportedMandatoryEvents(container, false));
  }, [containers, getUnreportedMandatoryEvents]);
}

export const useFocusedContainer = () => {
  const { focusedContainerId } = useFocusData();
  return useContainerById(focusedContainerId);
};
