import { Flex, Title, Text, Image, Button } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useFilterStore } from 'stores/filters';
import { logoLoopDark } from 'assets';

export const testIds = {
  emptyFiltersStub: 'empty-filters-stub',
  emptyStateClearFiltersBtn: 'empty-filters-clear-btn',
  emptyStateImg: 'empty-filters-img',
  emptyStateTitle: 'empty-filters-title',
  emptyStateText: 'empty-filters-text',
};

interface FiltersEmptyStateProps {
  title: string;
  text: string;
}

export const FiltersEmptyState = ({ title, text }: FiltersEmptyStateProps) => {
  const { t } = useTranslation();

  const { setUiEventFilterValues, setGlobalFilters } = useFilterStore(['setUiEventFilterValues', 'setGlobalFilters']);

  const clearFilters = () => {
    setUiEventFilterValues([]);
    setGlobalFilters({
      missingMandatory: false,
      outOfBounds: false,
    });
  };

  return (
    <Flex
      className='flex-1'
      direction='column'
      justify='center'
      align='center'
      h='100%'
      mih='300px'
      px={10}
      gap={10}
      data-testid={testIds.emptyFiltersStub}
    >
      <Image w={80} mb='sm' src={logoLoopDark} data-testid={testIds.emptyStateImg} />
      <Title size={20} fw='bold' ta='center' data-testid={testIds.emptyStateTitle}>
        {title}
      </Title>
      <Text fw={500} ta='center' w={212} data-testid={testIds.emptyStateText}>
        {text}
      </Text>
      <Button
        variant='outline'
        size='md'
        mt='lg'
        leftSection={<IconX size={18} />}
        onClick={clearFilters}
        data-testid={testIds.emptyStateClearFiltersBtn}
      >
        {t('inspection.emptyFilterResult.buttonText')}
      </Button>
    </Flex>
  );
};
