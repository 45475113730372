import { ActionIcon, Flex, Title } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { UserMenu } from 'layouts/UserMenu/UserMenu';
import { ReactNode } from 'react';

export const testIds = {
  actionNavbar: 'action-navbar',
  actionMenuDots: 'action-navbar-dots',
  title: 'action-navbar-title',
  rightSection: 'action-navbar-right-section',
};

interface ActionNavbarProps {
  title?: string;
  onBack?: () => void;
  rightSection?: ReactNode;
}

export const ActionNavbar = ({ title, onBack, rightSection }: ActionNavbarProps) => (
  <Flex align='center' justify='space-between' py={14} px={5} data-testid={testIds.actionNavbar}>
    <Flex align='center'>
      <UserMenu onBack={onBack} showUser>
        <ActionIcon display='flex' color='black' variant='transparent' data-testid={testIds.actionMenuDots}>
          <IconDotsVertical />
        </ActionIcon>
      </UserMenu>
      <Title className='break-all' order={1} fz='lg' fw={600} px='xs' lineClamp={1} data-testid={testIds.title}>
        {title}
      </Title>
    </Flex>
    {rightSection && (
      <Flex className='shrink-0' data-testid={testIds.rightSection}>
        {rightSection}
      </Flex>
    )}
  </Flex>
);
