import { createStoreHook } from '@aiola/frontend';
import { ContainerId } from '@flow/flow-backend-types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { focusStore } from 'stores/focus';
import { UIEventsMap, uiEventStore } from 'stores/uiEvent';
import { reportStore } from 'stores/report';
import { containerStore } from 'stores/container';
import { UiEventFilter, UiEventFilterValue, GlobalFilters } from './filters.types';
import { createUiEventFilters, processFilters } from './filters.utils';

const devtoolsOptions: DevtoolsOptions = {
  name: 'filters',
  store: 'filters',
  enabled: process.env.NODE_ENV === 'development',
};
interface FiltersState {
  isFiltersOpen: boolean;
  globalFilters: GlobalFilters;
  uiEventFilterValues: UiEventFilterValue[];
  uiEventFilters: UiEventFilter[];
  /** Either container without `childrenIds` or child container that match filters. */
  filteredContainerIds: Set<ContainerId>;
  /** Descendant children containers count that match filters */
  filteredContainerCounts: Record<ContainerId, number>;
}

interface FiltersActions {
  openFilters: () => void;
  closeFilters: () => void;
  setUiEventFilters: (uiEvents: UIEventsMap) => void;
  setGlobalFilters: (filters: Partial<GlobalFilters>) => void;
  setUiEventFilterValues: (values: UiEventFilterValue[]) => void;
  filterContainers: () => void;
  reset: () => void;
}

const filtersInitialState: FiltersState = {
  isFiltersOpen: false,
  uiEventFilterValues: [],
  uiEventFilters: [],
  globalFilters: {
    missingMandatory: false,
    outOfBounds: false,
  },
  filteredContainerIds: new Set(),
  filteredContainerCounts: {},
};

export const filterStore = create(
  devtools(
    immer<FiltersState & FiltersActions>((set, get) => ({
      ...filtersInitialState,
      setUiEventFilters: (uiEvents) => {
        const uiEventFilters = createUiEventFilters(uiEvents);
        set({ uiEventFilters });
      },
      setGlobalFilters: (filters = {}) => {
        set((state) => ({
          globalFilters: { ...state.globalFilters, ...filters },
        }));
        focusStore.getState().blurContainer();
      },
      setUiEventFilterValues: (values) => {
        set({ uiEventFilterValues: values });
        focusStore.getState().blurContainer();
      },
      openFilters: () => set({ isFiltersOpen: true }),
      closeFilters: () => set({ isFiltersOpen: false }),
      filterContainers: () => {
        const { uiEventFilterValues: filterValues, globalFilters } = get();
        const { rootContainerIds, containers, containerTemplatesMap, containerEventsMap } = containerStore.getState();
        const { uiEvents, visibilityBindings } = uiEventStore.getState();
        const { reports, validity, boundedness } = reportStore.getState();
        const { containerIds: filteredContainerIds, counts: filteredContainerCounts } = processFilters({
          rootContainerIds,
          containers,
          uiEvents,
          filterValues,
          containerTemplatesMap,
          containerEventsMap,
          globalFilters,
          reports,
          validity,
          boundedness,
          visibilityBindings,
        });

        set((state) => {
          state.filteredContainerIds = filteredContainerIds;
          state.filteredContainerCounts = filteredContainerCounts;
        });
      },
      reset: () => {
        set(filtersInitialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useFilterStore = createStoreHook({ store: filterStore, useShallow });
