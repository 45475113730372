import { create, StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';
import { enableMapSet } from 'immer';
import {
  Container,
  ContainerId,
  ContainerEvents,
  ExecutionId,
  DynamicContainer,
  PutContainersDoneRequest,
  GeneratedSource,
  GenerationMethod,
  ContainerTypeId,
  ContainerTemplates,
} from '@flow/flow-backend-types';
import { createStoreHook, generateId } from '@aiola/frontend';
import { focusStore } from 'stores/focus';
import { flowStore } from 'stores/flow';

import { db } from 'services/db';
import { ChildrenToParentMap, ContainersMap, PutDynamicContainerBody } from './container.types';
import { childToParentMap, getAllParentsRecursively, getDynamicContainersCountByTemplateId } from './container.utils';

enableMapSet();

const devtoolsOptions: DevtoolsOptions = {
  name: 'containers',
  store: 'containers',
  enabled: process.env.NODE_ENV === 'development',
};

interface ContainerDataSliceActions {
  setContainersData: (
    rootContainerIds: ContainerId[],
    containers: Record<ContainerId, Container>,
    containerEventsMap: Record<ContainerId, ContainerEvents>,
    containerTemplatesMap: Record<ContainerTypeId, ContainerTemplates>,
  ) => void;
  setContainers: (containers: ContainersMap) => void;
  createDynamicContainer: (
    containerTypeId: ContainerTypeId,
    flowExecutionId: ExecutionId,
    containerTitle?: string,
  ) => Promise<boolean>;
  removeContainer: (containerId: ContainerId) => void;
  onContainerAdded: (dynamicContainer: PutDynamicContainerBody | DynamicContainer) => Promise<void>;
  finishContainerEditing: (containerScheme: PutContainersDoneRequest) => void;
}

interface ContainerDataSliceState {
  containers: ContainersMap;
  rootContainerIds: ContainerId[];
  containerEventsMap: Record<ContainerId, ContainerEvents>;
  containerTemplatesMap: Record<ContainerTypeId, ContainerTemplates>;
}

interface ContainerUISliceActions {
  toggleParentContainerOpen: (containerId: string) => void;
  openContainerParents: (containerId: string) => void;
  openContainerLog: (containerId: string) => void;
  closeContainerLog: () => void;
  openTemplatesList: () => void;
  closeTemplatesList: () => void;
  setDCTitleTypeId: (typeId?: string) => void;
  closeAndClear: () => void;
}

interface ContainerUISliceState {
  openedLogContainerId: string | undefined;
  childParentMap: ChildrenToParentMap;
  openParentContainersMap: Set<string>;
  isTemplatesListOpen: boolean;
  dcTitleTypeId: string | undefined;
}

type ContainerState = ContainerDataSliceState &
  ContainerDataSliceActions &
  ContainerUISliceState &
  ContainerUISliceActions & { reset: () => void };

const dataSliceInitialState: ContainerDataSliceState = {
  containers: {},
  rootContainerIds: [],
  containerEventsMap: {},
  containerTemplatesMap: {},
};

const containerDataSlice: StateCreator<
  ContainerState,
  [['zustand/immer', never]],
  [],
  ContainerDataSliceState & ContainerDataSliceActions
> = (set, get) => ({
  ...dataSliceInitialState,
  setContainersData: (rootContainerIds, containers, containerEventsMap, containerTemplatesMap) => {
    set((state) => {
      state.containers = containers;
      state.rootContainerIds = rootContainerIds;
      state.childParentMap = childToParentMap(containers);
      state.containerEventsMap = containerEventsMap;
      state.containerTemplatesMap = containerTemplatesMap;
    });
  },
  setContainers: (containers: ContainersMap) => {
    set({ containers, childParentMap: childToParentMap(containers) });
  },
  createDynamicContainer: async (containerTypeId, flowExecutionId, containerTitle) => {
    const { containers, onContainerAdded, containerTemplatesMap } = get();
    const containerTemplate = containerTemplatesMap[containerTypeId];
    if (!containerTemplate) return false;
    const { typeTitle } = Object.values(containerTemplate)[0];
    const dynamicContainerIndex = getDynamicContainersCountByTemplateId(Object.values(containers), containerTypeId);
    const newContainerId = generateId();
    const dynamicContainerData: PutDynamicContainerBody = {
      id: newContainerId,
      title: containerTitle ?? `${typeTitle} ${dynamicContainerIndex + 1}`,
      creationTimestampClient: Date.now(),
      templateId: containerTypeId,
      flowExecutionId,
      generatedSource: GeneratedSource.UI,
      generationMethod: GenerationMethod.USER_ACTION,
    };

    await onContainerAdded(dynamicContainerData);
    focusStore.getState().focusContainer(newContainerId);
    await db.storePendingAction({
      type: 'putDynamicContainers',
      payload: {
        dynamicContainers: [dynamicContainerData],
      },
    });

    return true;
  },
  onContainerAdded: async (dynamicContainer) => {
    const { currentExecutionId } = flowStore.getState();
    const isDifferentExecution = dynamicContainer.flowExecutionId !== currentExecutionId;
    const { id, templateId, creationTimestampClient } = dynamicContainer;
    const { containerTemplatesMap, rootContainerIds, containers } = get();
    const containerTemplate = containerTemplatesMap[templateId];
    const containerAlreadyExists = rootContainerIds.includes(id) || containers[id];
    if (isDifferentExecution || !containerTemplate || containerAlreadyExists) return;
    const newContainer: Container = {
      ...dynamicContainer,
      isDynamic: true,
      containerTypeId: dynamicContainer.templateId,
      childrenIds: [],
      order: 0 - creationTimestampClient,
    };
    await db.storeDynamicContainers(currentExecutionId, [newContainer]);
    set((state) => {
      state.containers[id] = newContainer;
      state.rootContainerIds.unshift(id);
    });
  },
  finishContainerEditing: (containerScheme) => {
    db.storePendingAction({
      type: 'Done',
      payload: containerScheme,
    });
  },
  removeContainer: (containerId) => {
    const { rootContainerIds, containers } = get();
    if (!containers[containerId]) return;
    set((state) => {
      state.rootContainerIds = rootContainerIds.filter((id) => id !== containerId);
      delete state.containers[containerId];
    });
  },
});

const uiSliceInitialState: ContainerUISliceState = {
  openedLogContainerId: undefined,
  childParentMap: {},
  openParentContainersMap: new Set(),
  isTemplatesListOpen: false,
  dcTitleTypeId: undefined,
};

const containerUISlice: StateCreator<
  ContainerState,
  [['zustand/immer', never]],
  [],
  ContainerUISliceState & ContainerUISliceActions
> = (set, get) => ({
  ...uiSliceInitialState,
  toggleParentContainerOpen: (containerId: string) => {
    const { openParentContainersMap } = get();
    if (openParentContainersMap.has(containerId)) {
      set((state) => {
        state.openParentContainersMap.delete(containerId);
      });
    } else {
      set((state) => {
        state.openParentContainersMap.add(containerId);
      });
    }
  },
  openContainerParents: (containerId) => {
    const parents = getAllParentsRecursively(get().childParentMap, containerId);
    set((state) => {
      const container = state.containers[containerId];
      parents.forEach((id) => state.openParentContainersMap.add(id));
      if (container.childrenIds?.length) {
        state.openParentContainersMap.add(containerId);
      }
    });
  },
  openContainerLog: (containerId: string) => {
    set({ openedLogContainerId: containerId });
  },
  closeContainerLog: () => set({ openedLogContainerId: undefined }),
  openTemplatesList: () => set({ isTemplatesListOpen: true }),
  closeTemplatesList: () => set({ isTemplatesListOpen: false }),
  setDCTitleTypeId: (typeId) => set({ dcTitleTypeId: typeId }),
  closeAndClear: () => {
    focusStore.getState().blurContainer();
    set((state) => {
      state.dcTitleTypeId = undefined;
      state.openParentContainersMap.clear();
    });
  },
});

export const containerStore = create(
  devtools(
    immer<ContainerState>((...args) => ({
      ...containerDataSlice(...args),
      ...containerUISlice(...args),
      reset: () => {
        const [set] = args;
        set({ ...dataSliceInitialState, ...uiSliceInitialState });
      },
    })),
    devtoolsOptions,
  ),
);

export const useContainerStore = createStoreHook({ store: containerStore, useShallow });
