import { useSetState, useListState } from '@mantine/hooks';
import { UiEventId } from '@flow/flow-backend-types';
import { useEffect } from 'react';
import { UiEventFilterValue, ValidationFilter, GlobalFilters } from 'stores/filters';

export function useLocalFilters(uiEventFilterValues: UiEventFilterValue[], globalFilters: GlobalFilters) {
  const [uiEventFilterLocalValues, uiEventFilterLocalValuesHandlers] = useListState<UiEventFilterValue>();
  const [globals, setGlobals] = useSetState<GlobalFilters>(globalFilters);

  useEffect(() => uiEventFilterLocalValuesHandlers.setState(uiEventFilterValues), [uiEventFilterValues]);

  const aggregateUiEventFilterValues = (data: UiEventFilterValue[] = []) => {
    if (data.length) {
      return data.reduce<Record<UiEventId, string[]>>((acc, { uiEventId, valueFilters, validationFilters }) => {
        acc[uiEventId] = [...validationFilters, ...valueFilters];
        return acc;
      }, {});
    }
    return [];
  };

  const updateFilterValues = (uiEventId: UiEventId, newData: Partial<UiEventFilterValue>) => {
    const index = uiEventFilterLocalValues.findIndex((item) => item.uiEventId === uiEventId);
    if (index !== -1) {
      uiEventFilterLocalValuesHandlers.setItem(index, { ...uiEventFilterLocalValues[index], ...newData });
    } else {
      uiEventFilterLocalValuesHandlers.append({
        uiEventId,
        valueFilters: [],
        validationFilters: [],
        ...newData,
      });
    }
  };

  const handleUpdateValueFilters = (uiEventId: UiEventId) => (data: string[]) => {
    updateFilterValues(uiEventId, { valueFilters: data });
  };

  const handleUpdateValidationFilters = (uiEventId: UiEventId) => (data: ValidationFilter[]) => {
    updateFilterValues(uiEventId, { validationFilters: data });
  };

  return {
    globals,
    uiEventFilterLocalValues,
    setGlobals,
    setUiEventFilterLocalValues: uiEventFilterLocalValuesHandlers.setState,
    aggregateUiEventFilterValues,
    handleUpdateValueFilters,
    handleUpdateValidationFilters,
  };
}
