import { useDebugModeToggle } from 'hooks';
import { useEffect, useState } from 'react';
import { AppRoutes } from 'routes';
import { names, useSpy } from 'services/espionage';
import { logger } from 'services/logger';
import { useMixpanel } from 'services/mixpanel';
import { useWakeLock } from 'services/wakeLock';
import { useAuthStore } from 'stores/auth';

const App = () => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const { getCurrentUser, currentUser, createSession } = useAuthStore([
    'getCurrentUser',
    'currentUser',
    'createSession',
  ]);
  const { spyMount } = useSpy();
  useWakeLock(isAppInitialized);
  useMixpanel();
  useDebugModeToggle();

  useEffect(() => {
    (async () => {
      await createSession();
      await getCurrentUser();
      setIsAppInitialized(true);
      logger.info('App initialized');
    })();
    spyMount(names.App.Initialization);
  }, []);

  useEffect(() => {
    if (currentUser) {
      logger.setUser({ userId: currentUser.userId, userEmailAddress: currentUser.email, userName: currentUser.name });
      logger.info('User data set');
    }
  }, [currentUser]);

  return isAppInitialized ? <AppRoutes /> : null;
};

export default App;
