import { Container, ContainerTypeId } from '@flow/flow-backend-types';
import { ChildrenToParentMap, ContainersMap } from './container.types';

export function childToParentMap(containersMap: ContainersMap): ChildrenToParentMap {
  return Object.keys(containersMap).reduce((childToParent, childId) => {
    const parent = Object.values(containersMap).find(({ childrenIds }) => childrenIds?.includes(childId));
    childToParent[childId] = parent?.id;
    return childToParent;
  }, {} as ChildrenToParentMap);
}

export function getAllParentsRecursively(childToParent: ChildrenToParentMap, childId: string): string[] {
  const parentId = childToParent[childId];
  if (parentId) {
    return [parentId, ...getAllParentsRecursively(childToParent, parentId)];
  }
  return [];
}

export function getDynamicContainersCountByTemplateId(
  containers: Container[],
  containerTypeId: ContainerTypeId,
): number {
  return containers?.filter(({ isDynamic, containerTypeId: tId }) => !!isDynamic && containerTypeId === tId).length;
}

/** Sort container array by `order` in place. */
export function orderContainers(containers: Container[]) {
  containers.sort((a, b) => a.order - b.order);
  return containers;
}

/** Recursively DFS for leaf containers. Return them all sorted. */
export function filterLeafContainers(
  containers: ContainersMap,
  containerId: string,
  array: Container[] = [],
): Container[] {
  const container = containers[containerId];

  if (!container) return array;
  const isLeafContainer = !container.childrenIds?.length;
  if (isLeafContainer) {
    array.push(container);
    return array;
  }
  const childContainers = container.childrenIds?.map((childId) => containers[childId]) ?? [];
  orderContainers(childContainers);
  childContainers.forEach((child) => filterLeafContainers(containers, child.id, array));
  return array;
}
