import { UiEvent, UiEventId, ContainerId } from '@flow/flow-backend-types';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { isEventReported, getIsVisible, aggregateMultiSelectReports } from 'stores/uiEvent';
import { getReportCollectionKey, pullLastReport } from 'stores/report/report.utils';
import { exists } from 'utils';
import {
  ContainerDoesMatchFiltersParams,
  ProcessFiltersParams,
  IsEventMatchingGlobalFiltersParams,
  IsEventMatchingValueFiltersParams,
  IsEventValidAccordingToFiltersParams,
  UiEventFilter,
  ValidationFilter,
} from './filters.types';

export const createUiEventFilters = (uiEvents: Record<UiEventId, UiEvent>): UiEventFilter[] =>
  Object.values(uiEvents)
    .filter((event) => event.isFilterable)
    .map((event) => ({
      uiEventId: event.id,
      title: event.title,
      values: 'eventValues' in event.elementData ? event.elementData.eventValues : [],
      hasValidations: event.hasValidations,
    }));

export function isEventMatchingValueFilters({
  values,
  valueFilters,
  isContainerNotApplicable,
}: IsEventMatchingValueFiltersParams): boolean {
  if (isContainerNotApplicable) {
    return valueFilters.includes(ApplicabilityReportValue.NOT_APPLICABLE);
  }

  if (!values.length) {
    return valueFilters.includes(ApplicabilityReportValue.APPLICABLE);
  }

  return valueFilters.some((filter) => values.includes(filter));
}

export function isEventMatchingGlobalFilters({
  globalFilters,
  uiEvent,
  missingMandatory,
  bounded,
}: IsEventMatchingGlobalFiltersParams): boolean {
  if (!uiEvent) return false;
  if (globalFilters.outOfBounds) return bounded === false;
  if (globalFilters.missingMandatory) return missingMandatory === true;
  return true;
}

export function isEventValidAccordingToFilters({
  values,
  validationFilters,
  valid,
  isVisible,
}: IsEventValidAccordingToFiltersParams): boolean {
  return validationFilters.some((validationFilter) => {
    switch (validationFilter) {
      case ValidationFilter.Valid:
        if (!values.length) return false;
        return valid === true;
      case ValidationFilter.Invalid:
        if (!values.length) return false;
        return valid === false;
      case ValidationFilter.Unchecked:
        return !values.length && isVisible;
      case ValidationFilter.Checked:
        return !!values.length;
      default:
        return false;
    }
  });
}

export function containerDoesMatchFilters({
  container,
  uiEvents,
  filterValues,
  ceBridgeMap = {},
  globalFilters,
  reports,
  validity,
  boundedness,
  visibilityBindings,
}: ContainerDoesMatchFiltersParams): boolean {
  const applicabilityEventId = Object.values(ceBridgeMap).find(
    ({ uiEventId }) => uiEvents[uiEventId]?.type === 'ApplicabilityEvent',
  )?.uiEventId;
  const isContainerNotApplicable =
    pullLastReport(reports, container.id, applicabilityEventId ?? '')?.reportedValue ===
    ApplicabilityReportValue.NOT_APPLICABLE;
  // if no filters, this is true by default
  const matchedByValueFilters = filterValues.every(({ uiEventId, valueFilters, validationFilters }) => {
    // Check if the uiEvent is associated with the container
    if (!ceBridgeMap || !ceBridgeMap[uiEventId]) return false;

    if (!valueFilters.length && !validationFilters.length) return true;
    const key = getReportCollectionKey(container.id, uiEventId);
    const isMultiSelectEvent = uiEvents[uiEventId].type === 'MultiSelectEvent';
    const lastReport = pullLastReport(reports, container.id, uiEventId);
    const lastReportValues = lastReport?.reportedValue ? [lastReport.reportedValue] : [];
    const values = isMultiSelectEvent ? aggregateMultiSelectReports(reports[key]) : lastReportValues;
    const bridge = ceBridgeMap[uiEventId];
    const isVisible = getIsVisible({
      bridge,
      containerId: container.id,
      uiEvents,
      reports,
      visibilityBindings,
      validity,
    });

    const eventMatchingValueFilters = isEventMatchingValueFilters({
      values,
      valueFilters,
      isContainerNotApplicable,
    });

    const eventMatchingValidationFilters = isEventValidAccordingToFilters({
      values,
      validationFilters,
      valid: validity[key],
      isVisible,
    });

    return eventMatchingValueFilters || eventMatchingValidationFilters;
  });

  const matchedByGlobalFilters = Object.values(ceBridgeMap).some((ceBridge) => {
    const uiEvent = uiEvents[ceBridge.uiEventId];
    const isVisible = getIsVisible({
      bridge: ceBridge,
      containerId: container.id,
      uiEvents,
      reports,
      visibilityBindings,
      validity,
    });
    const missingMandatory = ceBridge.isMandatory && isVisible && !isEventReported(container.id, uiEvent, reports);
    const key = getReportCollectionKey(container.id, uiEvent.id);
    const bounded = boundedness[key];

    return isEventMatchingGlobalFilters({ uiEvent, globalFilters, missingMandatory, bounded });
  });

  return matchedByValueFilters && matchedByGlobalFilters;
}

/** Recursively collect container ids and count all children containers that match filters.
 * @returns `containerIds` - Set of container ids. Either container without `childrenIds` or child container that match filters.
 * @returns `counts` - Record of descendant children containers count that match filters
 */
export function processFilters({
  rootContainerIds,
  containers,
  uiEvents,
  filterValues,
  containerEventsMap,
  containerTemplatesMap,
  globalFilters,
  reports,
  validity,
  boundedness,
  visibilityBindings,
}: ProcessFiltersParams): {
  containerIds: Set<ContainerId>;
  counts: Record<ContainerId, number>;
} {
  const containerIds: Set<ContainerId> = new Set();
  const counts: Record<ContainerId, number> = {};

  function collectIdsAndCountDescendants(ids: ContainerId[]): number {
    return ids.reduce((acc, id) => {
      const curContainer = containers[id];
      if (!exists(curContainer)) return acc;
      const isParent = curContainer.childrenIds.length > 0;

      if (!isParent) {
        const doesContainerMatchFilters = containerDoesMatchFilters({
          container: curContainer,
          uiEvents,
          filterValues,
          ceBridgeMap: curContainer.isDynamic
            ? containerTemplatesMap[curContainer.containerTypeId]
            : containerEventsMap[curContainer.id],
          globalFilters,
          reports,
          validity,
          boundedness,
          visibilityBindings,
        });

        if (doesContainerMatchFilters) containerIds.add(id);

        return acc + Number(doesContainerMatchFilters);
      }

      const count = collectIdsAndCountDescendants(curContainer.childrenIds);

      if (count > 0) counts[curContainer.id] = count;

      return acc + count;
    }, 0);
  }

  collectIdsAndCountDescendants(rootContainerIds);

  return {
    containerIds,
    counts,
  };
}
