import { useEffect } from 'react';
import { Button, Drawer, Divider, Text, ScrollArea, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { INSPECTION_PAGE_LANDSCAPE_PERCENTAGE } from 'consts';
import { names, useSpy } from 'services/espionage';
import { useFilterStore, GlobalFilters } from 'stores/filters';
import { useAppStore } from 'stores/app';
import { EventFilterItem } from './EventFilterItem/EventFilterItem';
import { useLocalFilters } from './useLocalFilters';
import { GlobalFilterItems, testIds as globalFiltersTestIds } from './GlobalFilterItems/GlobalFilterItems';

export const testIds = {
  drawerContainer: 'filters-drawer-container',
  drawerContent: 'filters-drawer-content',
  closeDrawerBtn: 'filters-drawer-close',
  applyFiltersBtn: 'filters-apply',
  resetFiltersBtn: 'filters-reset',
  globalFilters: globalFiltersTestIds,
};

const { LEFT_SECTION_WIDTH } = INSPECTION_PAGE_LANDSCAPE_PERCENTAGE;

interface FiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const FiltersDrawer = ({ open, onClose }: FiltersDrawerProps) => {
  const { t } = useTranslation();
  const { spyMount, spyClick } = useSpy();
  const { isLandscape } = useAppStore(['isLandscape']);
  const {
    uiEventFilterValues,
    uiEventFilters,
    globalFilters,
    setUiEventFilterValues,
    setGlobalFilters,
    filterContainers,
  } = useFilterStore([
    'uiEventFilters',
    'uiEventFilterValues',
    'globalFilters',
    'setUiEventFilterValues',
    'setGlobalFilters',
    'filterContainers',
  ]);
  const {
    uiEventFilterLocalValues,
    globals,
    setUiEventFilterLocalValues,
    aggregateUiEventFilterValues,
    handleUpdateValueFilters,
    handleUpdateValidationFilters,
    setGlobals,
  } = useLocalFilters(uiEventFilterValues, globalFilters);

  const close = () => {
    spyClick(names.EventFilterDrawer.Close);
    setUiEventFilterLocalValues(uiEventFilterValues);
    onClose();
  };

  const reset = () => {
    spyClick(names.EventFilterDrawer.Reset);
    setUiEventFilterLocalValues([]);
    setGlobals({
      missingMandatory: false,
      outOfBounds: false,
    });
  };

  const apply = () => {
    const oldFilterSelection = aggregateUiEventFilterValues(uiEventFilterValues);
    const newFilterSelection = aggregateUiEventFilterValues(uiEventFilterLocalValues);
    spyClick(names.EventFilterDrawer.Apply, {
      oldFilterSelection,
      newFilterSelection,
      oldGlobalSelection: globalFilters,
      newGlobalSelection: globals,
    });
    setUiEventFilterValues(
      uiEventFilterLocalValues.filter(
        (filter) => filter.valueFilters.length > 0 || filter.validationFilters.length > 0,
      ),
    );
    setGlobalFilters(globals);
    filterContainers();
    onClose();
  };

  const onChangeGlobalFilter = (key: keyof GlobalFilters, value: boolean) => setGlobals({ [key]: value });

  useEffect(() => {
    if (open) spyMount(names.EventFilterDrawer.self);
  }, [open]);

  useEffect(() => {
    setGlobals(globalFilters);
    setUiEventFilterValues(uiEventFilterValues);
  }, [uiEventFilterValues, globalFilters]);

  return (
    <Drawer.Root
      position={isLandscape ? 'right' : 'bottom'}
      size={isLandscape ? LEFT_SECTION_WIDTH : '90%'}
      opened={open}
      onClose={close}
      data-testid={testIds.drawerContainer}
    >
      <Drawer.Overlay />
      <Drawer.Content className='overflow-hidden' data-testid={testIds.drawerContent}>
        <Drawer.Header>
          <Drawer.CloseButton data-testid={testIds.closeDrawerBtn} ml={0} />
          <Text c='gray.6' size='lg' fw={500}>
            {t('inspection.filter.drawerTitle')}
          </Text>
          <Button
            variant='subtle'
            c='blue.6'
            size='md'
            py='zero'
            h={28}
            fw={500}
            onClick={reset}
            data-testid={testIds.resetFiltersBtn}
          >
            {t('common.reset')}
          </Button>
        </Drawer.Header>
        <Divider size='xs' />
        <Drawer.Body className='flex flex-col' h='calc(100% - 60px)'>
          <ScrollArea h='calc(100% - 40px)' type='scroll'>
            <Stack className='overflow-auto' mt='md'>
              <GlobalFilterItems globalFilters={globals} onChange={onChangeGlobalFilter} />
            </Stack>
            {uiEventFilters.map((filter) => {
              const uiEventFilter = uiEventFilterLocalValues.find((item) => item.uiEventId === filter.uiEventId);
              return (
                <EventFilterItem
                  key={filter.uiEventId}
                  filter={filter}
                  filterValues={uiEventFilter?.valueFilters || []}
                  validationFilterValues={uiEventFilter?.validationFilters || []}
                  onFilterChanged={handleUpdateValueFilters(filter.uiEventId)}
                  onValidationFilterChanged={handleUpdateValidationFilters(filter.uiEventId)}
                />
              );
            })}
          </ScrollArea>
          <Divider mb='md' />
          <Button className='shrink-0' size='md' fullWidth onClick={apply} data-testid={testIds.applyFiltersBtn}>
            {t('common.apply')}
          </Button>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
