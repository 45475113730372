import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { osName } from 'react-device-detect';
import { NumberInput } from '@mantine/core';
import { NumericElementData, RangeValidation, ReportValueType } from '@flow/flow-backend-types';
import { exists, getNumericRangeText, parseNumberIfValid } from 'utils';
import { validateNumber } from 'stores/uiEvent';
import { getHotkeyHandler } from '@mantine/hooks';
import { NumericEventProps } from '../types';
import { EventValidityIcon, validityIconTestIds } from '../components';
import { useBoundError } from '../hooks';

export const testIds = {
  getInputTestId: (id: string) => `numeric-event-input-${id}`,
  getEditButtonTestId: (id: string) => `numeric-event-edit-button-${id}`,
  validity: validityIconTestIds,
};

export const NumericEvent = ({
  title,
  disabled,
  reportKey,
  elementData,
  validation,
  valid,
  bounds,
  bounded,
  isEditing,
  lastEventReport,
  triggerReport,
  triggerFocus,
  triggerBlur,
}: NumericEventProps<NumericElementData>) => {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLInputElement>(null);
  const lastReportedValue = parseNumberIfValid(lastEventReport?.reportedValue);
  const [value, setValue] = useState<number | undefined>(lastReportedValue);
  const error = useBoundError(bounded, bounds, value, 'NumericEvent');

  const reportEvent = () => {
    const isValid = validateNumber(value, validation);
    triggerReport(
      {
        reportedValue: exists(value) ? String(value) : null,
        reportedValueType: ReportValueType.NUMBER,
      },
      isValid,
    );
  };

  const blurElement = () => targetRef.current?.blur();

  const onBlur = () => {
    reportEvent();
    triggerBlur(targetRef.current?.value);
  };

  const onChange = (newValue: string | number) => {
    setValue(parseNumberIfValid(String(newValue)));
  };

  useEffect(() => {
    if (!isEditing) setValue(lastReportedValue);
  }, [lastReportedValue]);

  return (
    <NumberInput
      key={reportKey}
      ref={targetRef}
      allowDecimal
      inputMode={osName === 'Android' ? 'numeric' : undefined}
      description={validation && getNumericRangeText(validation as RangeValidation)}
      disabled={disabled}
      label={title}
      placeholder={elementData?.placeHolder ?? t('placeholders.number')}
      maxLength={10}
      decimalSeparator=','
      decimalScale={elementData?.decimalPrecision}
      value={value ?? ''}
      rightSection={<EventValidityIcon valid={valid} />}
      error={error}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={triggerFocus}
      onKeyDown={getHotkeyHandler([['Enter', blurElement]])}
      data-testid={testIds.getInputTestId(reportKey)}
    />
  );
};
