import { Flex, ActionIcon, Title, Text, useMantineTheme, Image } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { logoLoopDark } from 'assets';
import { names, NewDynamicContainerLocation, useSpy } from 'services/espionage';
import classes from './DynamicContainersEmptyState.module.css';

export const testIds = {
  emptyContainerListStub: 'empty-container-list-stub',
  emptyStateCreateDynamicContainerBtn: 'empty-state-create-dynamic-container-btn',
  emptyStateClearFiltersBtn: 'empty-state-clear-filters-btn',
  emptyStateImg: 'empty-state-img',
  emptyStateTitle: 'empty-state-title',
  emptyStateText: 'empty-state-text',
};

interface DynamicContainersEmptyStateProps {
  title: string;
  text: string;
  addContainer?: () => void;
}

export const DynamicContainersEmptyState = ({ title, text, addContainer }: DynamicContainersEmptyStateProps) => {
  const { colors } = useMantineTheme();
  const { spyClick } = useSpy();

  const onClickAddContainer = () => {
    spyClick(names.ExecutionPage.NewDynamicContainer, NewDynamicContainerLocation.ICON);
    addContainer?.();
  };

  return (
    <Flex
      className='flex-1'
      direction='column'
      justify='center'
      align='center'
      h='100%'
      mih='300px'
      px={10}
      gap={10}
      data-testid={testIds.emptyContainerListStub}
    >
      <Image w={80} mb='sm' src={logoLoopDark} data-testid={testIds.emptyStateImg} />
      <Title size={20} fw='bold' ta='center' data-testid={testIds.emptyStateTitle}>
        {title}
      </Title>
      <Text fw={500} ta='center' w={212} data-testid={testIds.emptyStateText}>
        {text}
      </Text>
      {addContainer && (
        <ActionIcon
          variant='default'
          size='xl'
          radius='xl'
          mt='sm'
          className={classes.newDynamicContainerBtn}
          onClick={onClickAddContainer}
          data-testid={testIds.emptyStateCreateDynamicContainerBtn}
        >
          <IconPlus color={colors.blue[6]} size={44} />
        </ActionIcon>
      )}
    </Flex>
  );
};
