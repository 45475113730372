import { useFocusedContainer, useContainerStore } from 'stores/container';
import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useAppStore } from 'stores/app';
import { useDynamicContainers } from 'pages/InspectionPage/InspectionPage.hooks';
import { ContainerItem } from '../ContainerItem/ContainerItem';
import { DynamicContainersEmptyState } from '../DynamicContainersEmptyState/DynamicContainersEmptyState';

export const testIds = {
  inspectionContainerDetails: 'inspection-container-details',
  emptyContainerDetails: 'empty-container-details',
};

export const LandscapeContainerSection = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const focusedContainer = useFocusedContainer();
  const { openTemplatesList } = useContainerStore(['openTemplatesList']);
  const { isDynamicContainers, hasMultipleDynamicContainers, createNewDynamicContainer } =
    useDynamicContainers(executionId);
  const { inspectionDataLoading } = useAppStore(['inspectionDataLoading']);
  const { t } = useTranslation();

  const emptyStateText = isDynamicContainers
    ? t('inspection.emptyContainerList.dynamicContainersText')
    : t('inspection.emptyContainerList.text');

  if (inspectionDataLoading) return null;

  const onAddContainer = () => {
    if (hasMultipleDynamicContainers) openTemplatesList();
    else createNewDynamicContainer();
  };

  return (
    <Flex h='100%' w='100%' data-testid={testIds.inspectionContainerDetails}>
      {focusedContainer ? (
        <ContainerItem container={focusedContainer} displayContent />
      ) : (
        <DynamicContainersEmptyState
          title={t('inspection.emptyContainerList.title')}
          text={emptyStateText}
          addContainer={isDynamicContainers ? onAddContainer : undefined}
        />
      )}
    </Flex>
  );
};
