import { Button, Box } from '@mantine/core';
import { useState } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { names, useSpy } from 'services/espionage';
import { ExecutionRouteParams, ROUTES } from 'routes/routes.config';
import { ActionNavbar } from 'layouts';
import { useFlowStore } from 'stores/flow';
import { useOnline } from 'stores/network';
import { modalManager } from 'services/modalManager';
import { testIds } from '../../SummaryPage';

interface SummaryNavbarProps {
  rating: number;
  comment?: string;
}

export const SummaryNavbar = ({ rating, comment }: SummaryNavbarProps) => {
  const [awaitingFinish, setAwaitingFinish] = useState(false);
  const { executionId } = useParams() as ExecutionRouteParams;
  const { finishExecution } = useFlowStore(['finishExecution']);
  const online = useOnline();
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const { spyClick } = useSpy();

  const finishInspection = async () => {
    const response = await finishExecution(executionId);

    if (response) {
      if (rating) {
        const value = {
          rating,
          ...(comment ? { comment } : {}),
        };
        spyClick(names.SummaryPage.Rating, value);
      }
      navigate(ROUTES.FLOWS);
    }

    setAwaitingFinish(false);
  };

  const onFinishInspection = async () => {
    setAwaitingFinish(true);
    spyClick(names.SummaryPage.Submit);
    if (online) {
      finishInspection();
    } else {
      modalManager.info({
        title: t('inspection.offlineSubmitModal.title'),
        message: t('inspection.offlineSubmitModal.message'),
        labels: { confirm: t('common.confirm'), cancel: '' },
        cancelProps: { display: 'none' },
        onConfirm: finishInspection,
      });
    }
  };

  const onBack = () => {
    spyClick(names.SummaryPage.Back);
    navigate(ROUTES.REVIEW_INSPECTION(executionId));
  };

  return (
    <Box className='shadow-md' mb='sm'>
      <ActionNavbar
        title={t('summary.title')}
        rightSection={
          <Button
            variant='subtle'
            size='compact-lg'
            px={0}
            bg='transparent'
            rightSection={<IconChevronRight className='-ml-1.5' />}
            loading={awaitingFinish}
            onClick={onFinishInspection}
            data-testid={testIds.submitButton}
          >
            {t('common.submit')}
          </Button>
        }
        onBack={onBack}
      />
    </Box>
  );
};
