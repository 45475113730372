import {
  UiEventId,
  ValueItem,
  ContainerId,
  Container,
  UiEvent,
  ContainerEvents,
  ContainerTypeId,
  ContainerTemplates,
  ReportedValue,
} from '@flow/flow-backend-types';
import { ContainersMap } from 'stores/container';
import { ReportedEventsMap, ReportValidityMap } from 'stores/report';
import { UIEventsMap, VisibilityBindingsMap } from 'stores/uiEvent';

export enum ValidationFilter {
  Valid = 'valid',
  Invalid = 'invalid',
  Checked = 'checked',
  Unchecked = 'unchecked',
}

export interface GlobalFilters {
  /** Filter containers with mandatory events that are missing a value. */
  missingMandatory: boolean;
  /** Filter containers with events that are out of defined bounds. */
  outOfBounds: boolean;
}

export interface UiEventFilter {
  uiEventId: UiEventId;
  title?: string;
  values: ValueItem[];
  hasValidations: boolean;
}

export interface UiEventFilterValue {
  uiEventId: UiEventId;
  valueFilters: string[];
  validationFilters: ValidationFilter[];
}

export interface ContainerDoesMatchFiltersParams {
  container: Container;
  uiEvents: UIEventsMap;
  ceBridgeMap: ContainerEvents;
  filterValues: UiEventFilterValue[];
  globalFilters: GlobalFilters;
  reports: ReportedEventsMap;
  validity: ReportValidityMap;
  boundedness: ReportValidityMap;
  visibilityBindings: VisibilityBindingsMap;
}

export interface ProcessFiltersParams {
  rootContainerIds: ContainerId[];
  containers: ContainersMap;
  containerEventsMap: Record<ContainerId, ContainerEvents>;
  containerTemplatesMap: Record<ContainerTypeId, ContainerTemplates>;
  uiEvents: UIEventsMap;
  filterValues: UiEventFilterValue[];
  globalFilters: GlobalFilters;
  reports: ReportedEventsMap;
  validity: ReportValidityMap;
  boundedness: ReportValidityMap;
  visibilityBindings: VisibilityBindingsMap;
}

export interface IsEventMatchingValueFiltersParams {
  values: ReportedValue[];
  valueFilters: string[];
  isContainerNotApplicable: boolean;
}

export interface IsEventMatchingGlobalFiltersParams {
  uiEvent?: UiEvent;
  globalFilters: GlobalFilters;
  bounded?: boolean;
  missingMandatory?: boolean;
}

export interface IsEventValidAccordingToFiltersParams {
  values: ReportedValue[];
  validationFilters: ValidationFilter[];
  valid?: boolean;
  isVisible?: boolean;
}
